import React, { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
const CloudConfig = () => {
  const [instance, setInstance] = useState(1)
  const [cpu, setCpu] = useState(1)
  const [ram, setRam] = useState(1)
  const [disk, setDisk] = useState(50)
  const [network, setNetwork] = useState(50)
  const [ipaddress, setIpAddress] = useState(1)
  const allData = useStaticQuery(graphql`
    query {
      allProductsJson(filter: { gid: { eq: 13 } }) {
        edges {
          node {
            pid
            name
            description
            pricing {
              INR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              MYR {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              SGD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              USD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
              CAD {
                prefix
                suffix
                monthly
                quarterly
                semiannually
                annually
                biennially
                triennially
              }
            }
            configoptions {
              configoption {
                options {
                  option {
                    name
                    pricing {
                      INR {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      MYR {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      SGD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      USD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                      CAD {
                        monthly
                        quarterly
                        semiannually
                        annually
                        biennially
                        triennially
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = allData.allProductsJson.edges

  console.log(data)

  const instancePrice =
    data[0].node.configoptions.configoption[0].options.option[0].pricing.INR
      .monthly
  const cpuPrice =
    data[0].node.configoptions.configoption[1].options.option[0].pricing.INR
      .monthly
  const ramPrice =
    data[0].node.configoptions.configoption[2].options.option[0].pricing.INR
      .monthly
  const diskPrice =
    data[0].node.configoptions.configoption[3].options.option[0].pricing.INR
      .monthly
  const networkPrice =
    data[0].node.configoptions.configoption[4].options.option[0].pricing.INR
      .monthly
  const ipaddressPrice =
    data[0].node.configoptions.configoption[5].options.option[0].pricing.INR
      .monthly

  let totalInstancePrice = instance * instancePrice
  let totalCpuPrice = cpu * cpuPrice
  let totalRamPrice = ram * ramPrice
  let totalDiskPrice = disk * diskPrice
  let totalNetworkPrice = network * networkPrice
  let ipAddressPrice = ipaddress * ipaddressPrice
  let totalcost =
    totalInstancePrice +
    totalCpuPrice +
    totalRamPrice +
    totalDiskPrice +
    totalNetworkPrice +
    ipAddressPrice

  return (
    <>
      <div className="cloud-config-panel p-3 p-lg-4 bg-white">
        <p className="title mb-2">How much Managed Cloud Server cost me?</p>
        <div className="total_price">
          {/* <p>Estimate cost per month (INR)</p><span className="text-4xl font-bold">{totalcost.toFixed(2)}</span> */}
        </div>
        <div className="choose_settings">
          <div className="instance form-group position-relative">
            <label htmlFor="instance" className="fw-bold">
              {
                data[0].node.configoptions.configoption[0].options.option[0]
                  .name
              }
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="instance"
              name="instance"
              min="1"
              max="1"
              step="1"
              value={instance}
              aria-label="instance slider"
              onChange={(event) => setInstance(event.target.value)}
            />
            <div className="position-absolute value">{instance} Instance</div>
            <div></div>
          </div>
          <div className="CPU form-group position-relative">
            <label htmlFor="cpu" className="fw-bold">
              {
                data[0].node.configoptions.configoption[1].options.option[0]
                  .name
              }
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="cpu"
              name="cpu"
              min="1"
              max="24"
              step="1"
              value={cpu}
              aria-label="cpu slider"
              onChange={(event) => setCpu(event.target.value)}
            />
            <div className="position-absolute value">{cpu} Core</div>
            <div></div>
          </div>
          <div className="RAM form-group position-relative">
            <label htmlFor="ram" className="fw-bold">
              {
                data[0].node.configoptions.configoption[2].options.option[0]
                  .name
              }
              (GB)
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="ram"
              name="ram"
              min="1"
              max="128"
              step="1"
              value={ram}
              aria-label="ram slider"
              onChange={(event) => setRam(event.target.value)}
            />
            <div className="position-absolute value">{ram} GB</div>
            <div></div>
          </div>
          <div className="DISK form-group position-relative">
            <label htmlFor="disk" className="fw-bold">
              {
                data[0].node.configoptions.configoption[3].options.option[0]
                  .name
              }
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="disk"
              name="disk"
              min="50"
              max="2000"
              step="25"
              value={disk}
              aria-label="disk slider"
              onChange={(event) => setDisk(event.target.value)}
            />
            <div className="position-absolute value">{disk} GB</div>
            <div></div>
          </div>
          <div className="Network form-group position-relative">
            <label htmlFor="network" className="fw-bold">
              {
                data[0].node.configoptions.configoption[4].options.option[0]
                  .name
              }
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="network"
              name="network"
              min="50"
              max="1000"
              step="50"
              value={network}
              aria-label="network slider"
              onChange={(event) => setNetwork(event.target.value)}
            />
            <div className="position-absolute value">{network} GB</div>
            <div></div>
          </div>
          <div className="Ipaddress form-group position-relative">
            <label htmlFor="ipaddress" className="fw-bold">
              {
                data[0].node.configoptions.configoption[5].options.option[0]
                  .name
              }
            </label>
            <input
              className="form-control-range slider"
              type="range"
              id="ipaddress"
              name="ipaddress"
              min="1"
              max="10"
              step="1"
              value={ipaddress}
              aria-label="ipaddress slider"
              onChange={(event) => setIpAddress(event.target.value)}
            />
            <div className="position-absolute value">{ipaddress}</div>
            <div></div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-32 fw-bolder">
              <span className="ps-0 pe-1">₹</span>
              {totalcost.toFixed(2)}
              <span className="fs-16">/mo</span>
            </div>
            <a
              className="btn btn-outline-dark"
              href="https://my.motherhost.com/cart.php?a=add&pid=53"
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default CloudConfig
